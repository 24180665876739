/*
*******************************************
Template Name: mywillmyway-legal
Author:Nikhil Emmanuel
Date: 30-05-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the mywillmyway-legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - SECT
05 - section fut
06 - FOOTER
07 - MODAL
08 - Animation
09 - MEDIA QUERY

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

$background_color_1: #fff;
$background_color_2: #ddd;
$background_color_3: #f8f8f8;
$background_color_4: #0566a1;
$background_color_5: #086eb5;

$color_1: #fff;
$color_2: #777777;
$color_3: #445870;
$color_4: #a7a7a7;
$color_5: #000;
$color_6: #006ebe;
$color_7: #898c8d;
$color_8: #03588b;

$font-bold: bold;
$font-w700: 700;
$font-w600: 600;
$font-w400: 400;

$font_family_1: "Lato", sans-serif;

$font_size_1: 16px;
$font_size_2: 14px;
$font_size_3: 44px;
$font_size_4: 17px;
$font_size_5: 15px;
$font_size_6: 18px;
$font_size_7: 12px;
$font_size_8: 20px;
$font_size_9: 28px;
$font_size_10: 32px;
$font_size_11: 30px;
$font_size_12: 25px;
$font_size_13: 24px;

// ======border===========
$border-style1: 2px solid #d9d9d9;
$border-style2: 3px solid #033d60;
$border-style3: solid 5px #e8e8e8;

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

.progress-bar {
  width: 20%;
  background: $background_color_4 !important;
}

a {
  color: #007bff !important;
  cursor: pointer;
}

body {
  font-family: $font_family_1 !important;
  background: $background_color_2 !important;
}
.text-style{
  font-size: 25px !important;
}
.mywillmyway:before {
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  height: 346px;
  background-image: url(../img/bannersbg.jpg);
  background-size: cover;
  content: "";
  z-index: -1;
}

header {
  padding: 15px;
  background: $background_color_1;
  .logo {
    height: 80px;
  }
  .logo1 {
    height: 80px;
  }
}

/*================================================
04 - SECT
==================================================*/

.SECT {
  margin-bottom: 10px;
}

label {
  color: $color_2;
  font-size: $font_size_8;
  font-weight: $font-w700;
  line-height: 24px;
  width: 100%;
  display: block;
  margin-top: 15px;
  text-align: center;

  input[type="radio"] {
    display: none !important;
  }
}

.form_part {
  background: $background_color_1;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.37);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.37);
  padding: 20px 0;
  width: 85%;
  margin: 20px auto;
  padding: 0px;
  h1 {
    font-size: 42px;
    color: $color_8 !important;
    font-weight: $font-w700;
    text-align: center;
    padding: 15px;
    margin-bottom: 0px;
  }

  h2 {
    margin: 6px 0px 15px;
    font-size: 30px;
    text-align: center;
    color: $color_8 !important;
  }

  .greenspan {
    background-color: #87c44a !important;
  }

  .headspan {
    color: #7abf39;
  }

  .form-control {
    background-color: $background_color_1;
    border: $border-style1;
    width: 90%;
    border-radius: 5px;
    display: inline-block;
    margin-top: 5px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    height: 55px;
    font-size: $font_size_1;
    font-weight: $font-w400;
    width: 100%;
    padding: 12px 30px 12px 15px;
    color: $color_3;
  }

  .logos {
    background-color: $background_color_3;
    padding: 10px -15px !important;
    position: relative;
    margin: 10px 0;
    padding: 0px 8%;

    .providers {
      text-align: center;
      padding: 0px;

      .providers-item {
        display: inline-block;
        list-style: none;
        text-align: center;
        padding: 10px;

        img {
          width: 70%;
          border: medium none;
          margin-right: 10px;
        }
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 7px;
    .mar {
      margin: 0 auto;
      text-align: center;
    }
  }

  #slide1,
  #slide2,
  #slide4,
  #slide6 {
    .icon_option {
      background: $background_color_4;
      border-bottom: $border-style2;
      font-size: $font_size_1;
      color: $color_1;
      font-weight: $font-w600;
      box-sizing: border-box;
      width: 75%;
      margin-top: 15px;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      text-align: center;
      padding: 20px 15px;

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .icon_option.active {
      border-color:#010608;
      background-color:#032234;;
      z-index: 100;
      color: $color_1;
    }
  }

  #slide3, #what_is_important_slide {
    .options {
      margin-bottom: 0px !important;
    }
    .popupLink {
      list-style: none;
      .defaultchk {
        display: block !important;
        position: relative;
        padding-left: 50px !important;
        margin-bottom: 12px !important;
        cursor: pointer;
        font-size: $font_size_1;
        text-align: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .defaultchk input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      .defaultchk input:checked ~ .checkmark {
        background-color: $background_color_4;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .defaultchk input:checked ~ .checkmark:after {
        display: block;
      }
      .defaultchk .checkmark:after {
        left: 10px;
        top: 3px;
        width: 8px;
        height: 18px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  #slide10, #what_is_important_slide {
    .options {
      margin-bottom: 0px !important;
    }
    .popupLink {
      list-style: none;
      .defaultchk {
        display: block !important;
        position: relative;
        padding-left: 50px !important;
        margin-bottom: 12px !important;
        cursor: pointer;
        font-size: $font_size_1;
        text-align: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .defaultchk input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      .defaultchk input:checked ~ .checkmark {
        background-color: $background_color_4;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .defaultchk input:checked ~ .checkmark:after {
        display: block;
      }
      .defaultchk .checkmark:after {
        left: 10px;
        top: 3px;
        width: 8px;
        height: 18px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .mobpad10 {
    margin-top: 5px !important;
  }

  .authorise {
    p {
      font-size: $font_size_5;
    }
  }

  .consent {
    padding-bottom: 20px;
    p {
      font-size: $font_size_5;
      color: $color_5;
    }
  }

  .bord {
    padding: 0px 0px 15px;
    margin-top: 0px;
    .safe-text {
      font-size: $font_size_7;
      color: $color_4;
    }
    .my-bt {
      margin: 10px 0px !important;
      font-size: $font_size_9;
      border-radius: 5px;
      color: $color_1;
      font-weight: $font-w700 !important;
      padding: 12px 0;
      width: 55%;
      -webkit-box-shadow: 0px 4px 0px 0px #086eb5;
      -moz-box-shadow: 0px 4px 0px 0px #086eb5;
      box-shadow: 0px 4px 0px 0px #086eb5;
      background: $background_color_4;
    }

    .my-bts {
      margin: 10px 0px !important;
      font-size: $font_size_9;
      border-radius: 5px;
      color: $color_1;
      font-weight: $font-w700 !important;
      padding: 15px 0;
      width: 55%;
      background: $background_color_4;
    }
    .call-btn{
      padding: 0px !important;
      .next-but{
        padding: 0px;
      }
    }

    .my-btttnes {
      margin: 10px 0px !important;
      font-size: $font_size_9;
      border-radius: 5px;
      color: $color_1;
      font-weight: $font-w700 !important;
      padding: 12px 0;
      width: 55%;
      -webkit-box-shadow: 0px 4px 0px 0px #0a873e;
      -moz-box-shadow: 0px 4px 0px 0px #0a873e;
      box-shadow: 0px 4px 0px 0px #0a873e;
      background: $background_color_4;
    }

    .back_btn {
      font-size: $font_size_8;
      color: $color_6 !important;
      padding: 10px 0;
      cursor: pointer;
    }
  }

  .Lookup_but {
    background: $background_color_4;
    padding: 12px 14px;
    font-size: $font_size_12;
    color: $color_1 !important;
    text-align: center;
    width: 50%;
    margin-bottom: 15px;
  }

  .para {
    font-size: $font_size_8;
    color: $color_2;
    margin-bottom: 6px;
  }

  .paragrap {
    font-size: $font_size_1;
  }

  .yellow {
    background: yellow;
  }
}





  ul{
      padding-left: 0px;
    .tiksec{
      font-size: 15px;
      list-style: none;
      font-weight:normal;
      font-size: 16px;
      list-style: none;
      background: url(../img/li.png) no-repeat left center;
      padding: 8px 5px 7px 40px;
      margin: 3px 5px 3px 0;
      border-radius: 7px;
      float: left;
    }
  }







.input-group-text {
  padding: 0px 5px !important;
  height: 55px !important;
  border-radius: 0px 5px 5px 0px !important;
  top: 5px;
  position: relative;
}

.fut {
  background-color: #097dc0;
  padding: 35px 0px;
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  h3 {
    text-align: end;
    font-size: 35px;
  }
  .futsection {
    padding: 30px 0px;
  }
}

/*================================================
06 - FOOTER
==================================================*/
footer {
  width: 100%;
  height: auto;
  padding-top: 22px;
  background-color: $color_8 !important;

  .spine {
    margin: 0 auto;
    padding: 0;
    width: 83.5%;
    height: auto;

    p {
      font-size: $font_size_2;
      float: none;
      text-align: center;
      margin-bottom: 6px;
    }
  }

  p {
    font-size: $font_size_2;
    margin-bottom: 6px;
  }

  ul {
    text-align: center;
    margin: 5px auto;
    padding: 0;

    li {
      margin: 0 3px;
      padding: 0;
      display: inline;
      vertical-align: baseline;
      font-size: $font_size_5;
      color: $color_7;

      a {
        color: $color_1 !important;
        text-decoration: none;
      }
    }
  }
}

/*================================================
07 - MODAL
==================================================*/

.modal-content {
  .modal-header {
    h4 {
      font-size: 28px;
    }
  }

  .modal-body {
    p {
      font-size: 15px;
    }
    h3 {
      font-size: 17px;
      font-weight: 600;
    }
  }

  .close {
    background: #fff;
    border: none;
    font-size: 30px;
    color: #000;
  }

  table {
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid #000;
  }

  td {
    border-right: 1px solid #000;
    padding: 5px;
  }
}

/*================================================
08 - Animation
==================================================*/

.anim_ylw {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #007bff8f;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
    height: 10px;
  }
  50% {
    opacity: 1;
    height: 14px;
  }
  100% {
    opacity: 1;
    height: 18px;
  }
}

/*================================================
animated page
==================================================*/

.animatelogo {
  background-color: #fff;

  img {
    width: 30%;
  }
}

.animategif {
  background-color: #fff;
  height: 300px;
  p {
    font-size: 20px;
    font-weight: 600;
  }
}

/*================================================
thanku page
==================================================*/

.thankupart {
  .logo {
    img {
      height: 80px;
    }
  }
}

.thankusec {
  background: url(../img/bg.jpg) no-repeat right;
  background-size: cover;
  padding: 165px 0px;

  h1 {
    font-size: 46px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    color: #ffffff;
  }
}

/*================================================
09 - MEDIA QUERY
==================================================*/

$break-ipad-pro: 1024px;
$break-min-ipad: 768px;
$break-max-ipad: 990px;
$break-min-tab: 577px;
$break-max-tab: 767px;
$break-mob: 576px;

@media (min-width: $break-max-ipad) and (max-width: $break-ipad-pro) {
  .form_part form .form_cont .form-group {
    .icon_option {
      width: 90% !important;
      margin-right: 5px !important;
    }
  }

  .thankusec{

    padding: 0px;

  }

  .overly{
    background: #0000009e ;
    padding: 100px 15px;
  }

  .fut {
    margin-bottom: 100px;
    h3 {
      text-align: end;
    }
  }
  footer{
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: $break-min-ipad) and (max-width: $break-max-ipad) {
  .thankusec {
    padding: 0px;
    min-height: calc(100vh - 240px);
  }

  .overly{
    background: #0000009e ;
    padding: 100px 15px;
  }

  .mywillmyway:before {
    background-size: cover;
    height: 200px;
  }

  .form_part {
    width: 87% !important;
    h1 {
      font-size: $font_size_10;
    }
  }
  .my-btttnes {
    width: 80% !important;
  }

  .fut {
    h3 {
      text-align: end;
    }
  }
  // footer{
  //   position: fixed;
  //   bottom: 0;
  // }
}

@media (min-width: $break-min-tab) and (max-width: $break-max-tab) {

  header{
    .logo1{
      width: 100% !important;
      height: auto !important;
      margin-top: 16px;
    }
  }

  .thankusec {
    padding: 0px;
    .overly{
      background: #0000009e ;
      padding: 70px 15px;
    }
  }

  .form_part {
    width: 95%;
    h1 {
      text-align: center;
      font-size: $font_size_11 !important;
    }
    .icon_option {
      width: 85% !important;
    }
    .my-bt {
      width: 74% !important;
    }
    .my-btttnes {
      width: 80% !important;
      font-size: $font_size_8 !important;
    }
  }

  .providers {
    display: inline-flex !important;
  }

  .fut {
    h3 {
      text-align: center;
    }
  }
}

@media screen and (max-width: $break-mob) {
  .animatelogo img {
    width: 65% !important;
  }

  .thankusec {
    padding: 0px;
    min-height: calc(100vh - 250px);

    h1 {
      text-shadow: none;
      font-size: 31px;
    }
    p {
      text-shadow: none;
      font-size: 16px;
    }

    .overly{
      background: #0000009e ;
      padding: 50px 15px;
    }
  }

  .start-but {
    width: 100px;
    height: 100px;
    border: 5px solid #e8e8e8;
    position: relative;
    border-radius: 100%;
    display: inline-block;
    color: #8c8c8c;
    font-size: 18px;
    margin-top: -45px;
    margin-bottom: 5px;
    background: #fff;
    padding-top: 30px;
    line-height: 18px;
  }

  .mywillmyway:before {
    position: absolute;
    top: 0px !important;
  }

  .SECT {
    margin-bottom: 0px;
    &::before {
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 332px;
      z-index: -1;
    }
    h1 {
      font-size: $font_size_9 !important;
      text-align: center;
      margin-bottom: 0px;
      color: $color_1;
      padding: 60px 15px 8px;
      line-height: 40px;
      font-weight: $font-bold;
    }

    h2 {
      font-size: $font_size_13 !important;
      text-align: center;
      margin-bottom: 0px;
      color: $color_1;
      line-height: 30px;
      padding: 15px 15px 50px;
    }

    .pad0 {
      padding: 0px !important;
    }
  }

  header {
    display: none !important;
  }

  .thankupart {
    display: block !important;
  }

  .form_part {
    width: 100% !important;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-bottom: 30px;

    form {
      .form_cont {
        .form-group {
          margin-bottom: 7px !important;
          .icon_option {
            width: 100% !important;
            margin-top: 0px !important;
            margin-bottom: 6px !important;
          }
          label {
            margin: 0 !important;
            padding: 15px 0;
            font-size: $font_size_8;
          }
        }
        .options {
          padding: 0px;
        }
        .icon_option2 {
          font-size: $font_size_4 !important;
        }
        .mobload {
          margin-bottom: 0px !important;
        }

        .form-control {
          margin-bottom: 6px !important;
        }

        .mobpad10 {
          padding: 0px !important;
        }
      }
    }

    #slide1,
    #slide2 {
      .icon_option {
        font-size: $font_size_8 !important;
      }
      .defaultchk {
        padding: 0px !important;
        font-size: $font_size_4 !important;
      }
    }

    #slide3 {
      .custom-checkbox + label {
        font-size: $font_size_1 !important;
        padding: 0px 0px 0px 10px !important;
      }
      .popupLink {
        .checkmark {
          top: 13px !important;
        }
      }

      .form-group {
        label {
          padding: 5px 0px !important;
        }
      }
    }

    .bord {
      padding: 5px 0 10px;
      .my-bt {
        width: 90%;
        font-size: $font_size_12;
        padding: 15px 0px;
      }

      .my-bts {
        width: 90%;
        font-size: $font_size_12 !important;
        padding: 15px 0px;
      }
      .my-btttnes {
        width: 90%;
        font-size: $font_size_12 !important;
        padding: 15px 0px;
      }

      .back_btn {
        font-size: $font_size_1 !important;
      }
    }

    .consent {
      padding-bottom: 0px !important;
      p {
        font-size: $font_size_7 !important;
      }
    }

    .authorise {
      p {
        font-size: $font_size_7 !important;
      }
    }
  }

  .fut {
    padding: 50px 0px 35px 0px;
    h3 {
      text-align: center;
    }
    .firstsec,
    .secondsec,
    .thirdsec {
      padding-bottom: 20px !important;
    }
    .futsection {
      padding: 15px 0px;
    }
  }

  footer {
    img {
      width: 60% !important;
    }
    ul {
      padding: 6px !important;
      li {
        font-size: $font_size_7 !important;
      }
    }
    p {
      font-size: $font_size_7 !important;
    }
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.error_msg {
  color: #fff;
  width: 100%;
  float: left;
  background: red;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  text-align: center;
  margin-bottom: 5px;
}
