 


 $break-mob: 576px;

 @media screen and (max-width: $break-mob) {

    .bannergroup {
        h1{
            padding: 30px 15px 8px;
        }
        &::before {
          height: 380px !important;
        }
    }

    .secondwillsec{
        &::before {
            height: 380px !important;
        }
    }
 }
 @media only screen and (min-width: 768px) {


    .deskh2 {
        margin: 6px 7px 15px;
        font-size: 28px !important;
    }
    
    
    }