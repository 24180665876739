@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700&display=swap');

$color_2: #636363;
$font_family_1:'Titillium Web',
sans-serif;
body {
  font-family: $font_family_1 !important;
  font-size: 14px;
  line-height: 1.5;
  color: $color_2;
  background: #ffff;
}
.HomeLoanCredit_Unqualified_TY_page {
  /*
    *******************************************
    Template Name: Home Loan Credit
    Author: Manu Joseph
    Date: 27-01-2022
    Template By: Vandalay Designs
    Copyright 2020-2021 Vandalay Business Solution
    
    * This file contains the styling for the Home Loan Credit, this
    is the file you need to edit to change the look of the
    site.
    
    This files contents are outlined below >>>>
    
    *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
    *******************************************
    ============================================
    ==== T A B L E   O F   C O N T E N T S =====
    ============================================
    01 - Sass Variables
    02 - BODY 
    03 - FOOTER 
    04 - MODAL
    05 - MEDIA QUERY
  /*================================================
    01 - Sass Variables
    ==================================================*/
  $background_color_1: #ffffff;
  $background_color_2: #69a2d7;
  $background_color_3: #69a2d7;
  $background_color_4: #333333;
  $background_color_5: #257eba;
  $background_color_6: #545454;
  $background_color_7: #2f9de8;
  $color_1: #ffffff;
  $color_2: #777777;
  $color_3: #04ad61;
  $color_4: #beb8b8;
  $color_5: #009c7f;
  $color_6: #fce4c7;
  $color_7: #000000;
  $color_8: #2f9de8;
  $font_size_1: 12px;
  $font_size_2: 14px;
  $font_size_3: 15px;
  $font_size_4: 16px;
  $font_size_5: 18px;
  $font_size_6: 20px;
  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  // ======Border Styles===============
  $border_color_1: #fff;
  /*================================================
    02 - BODY 
  ==================================================*/
  html {
    scroll-behavior: smooth;
  }
  a {
    cursor: pointer;
  }
  header {
    background-color: #004f92;
    border-bottom: solid 3px #FDBB4D;
    padding: 20px 0px;
    img {
      height: 45px;

    }
  }
  section {
    padding: 80px 0px;
    img {
      width: 40%;
    }
    h1 {
      font-size: 60px;
      font-weight: 800;
      color: #ff0000;
    }
    p {
      font-size: 22px;
    }
  }
  /*================================================
    03 - FOOTER
  ==================================================*/
  footer {
    background: #21212d;
    padding: 55px 60px 25px;
    margin-top: 0px;
    p {
      font-size: 13px;
      padding-top: 0;
      margin-bottom: 16px !important;
      font-weight: 400;
      line-height: 25px;
      color: #fff;
    }
    ul {
      margin: 10px 0;
      padding: 0px;
      text-align: center;
      li {
        list-style: none;
        display: inline;
        margin: 0px 13px 0 0;
        padding-right: 10px;
        border-right: 1px solid #fff;
        &:last-child {
          border: none;
        }
        a {
          color: #fff;
          font-size: 15px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .lft {
      text-align: left;
    }
    .rht {
      text-align: right;
      img {
        height: 100px;
      }
    }
    .copyright {
      padding-top: 15px;
      margin-top: 20px;
      border-top: 2px groove #d2d2d2;
    }
  }
  /*================================================
    04 - MODAL
  ==================================================*/
  .modal {
    .modal-content {
      border: 4px solid #195071 !important;
      .modal-header {
        background: #195071;
        border-radius: 0px !important;

        .modal-title {
          color: #fff;
        }
      }
      .modal-footer {
        background: #195071;
        border: 0px;
        padding: 10px 20px !important;
      }
    }
  }
  /*================================================
    05 - MEDIA QUERY
  ==================================================*/
  @media (min-width: 992px) and (max-width: 1199.98px) {}
  @media screen and (max-width: 991.98px) and (min-width: 768px) {}
  @media screen and (max-width: 767px) {}
  @media (max-width: 576px) {
    section {
      img {
        width: 60%;
      }
    }
    footer {
      padding: 55px 25px 25px;
    }
  }
}