/*
*******************************************
Template Name: Mywillmyway-legal
Author: Nikhil Emmanuel
Date: 01-06-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for Mywillmyway-legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - SECTION 
06 - FOOTER 
07 - MODAL 
08 - MEDIA QUERY

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$background_color_1:#097dc0;
$background_color_2:#03588b;

// ======font styles===============

$color_1:#ffffff;
$color_2: #000;
$color_3:#303291;


$font_family_1:'Open Sans', sans-serif !important;

$font_size_1:14px;
$font_size_2:22px;
$font_size_3:28px;
$font_size_4:26px;
$font_size_5:18px;
$font_size_6:15px;
$font_size_7:17px;
$font_size_8:20px;

$font-bold:bold;
$font-w600:600;
$font-w700:800;


// ======border===========


/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

html {
	scroll-behavior: smooth;	
}

body{
	font-family: $font_family_1;
	font-size: $font_size_1;
	background: $color_1 !important;
	color:  $color_2 !important;
}

a{
	cursor: pointer;
}

/*================================================
04 -header
==================================================*/

header{
    img{
        height: 90px;
    }
}

/*================================================
05 -content_part
==================================================*/

.content_part{
	background-image: url(../img/mainbanner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 76%;
    height: 705px;
	position: relative;
	border-radius: 8px;
	.whitebox{
		padding: 30px;
		position: absolute;
		bottom: 16px;
		left: 16px;
    	right: 16px;
		border-radius: 6px;

		h1{
			font-size: $font_size_3;
			font-weight: $font-w700;
		}
		.contentbtn{
			padding: 10px 0px;
			background-color: $background_color_1;
			font-size: $font_size_5;
			border-radius: 6px;
			margin: 22px auto;
			font-weight: $font-w600;
		}
		.btntext{
			font-size: 12px;
		}
	}
}


.textarea{
	h3{
		font-size: 22px;
		font-weight: $font-w700 !important;
	}
	// .yellowsec{
	// 	background: #FCEC0B;
	// }
}

/*================================================
06 -agesec
==================================================*/


/*================================================
06 -partnersec
==================================================*/

.partnersec{
	.age_button_inner {
		background: #097dc0;
		height: auto;
		// padding: 5px;
		margin-bottom: 0px;

		.age_button_inner_bg {
			background: #fff;
			padding: 0 0 20px 0;
			text-align: center;

			h2{
				background: #002f4a;
				font-weight: $font-w700 !important;
				color: #fff;
				font-size: 24px;
				padding: 13px 5px;
				border-bottom: none;
				margin-bottom: 15px;
			}
			a {
				padding: 4px 32px;
				font-size: 14px;
				font-weight: $font-w700 !important;
				margin: 2px 1px;
				line-height: 34px;
				color: #fff !important;
				background: #097dc0;
				border-radius: 0;
				display: inline-block;
				text-decoration: none;

				&:hover{
					background: #055583;	
				}
			}
		}
	}
	
}

/*================================================
06 -reasontext
==================================================*/

.reasontext{
	.quotebtn{
		background: $background_color_1;
		font-weight: $font-w700;
		font-size: $font_size_5;
	}
	h3, h4, h5{
		font-size: $font_size_2;
		font-weight: $font-w700 !important;
	}
}

/*================================================
06 -footer
==================================================*/

footer {
    padding-top: 22px;
    background-color: $background_color_2 !important;
	ul {
		margin: 5px auto;
		li {
			margin: 0 3px;
			padding: 0;
			display: inline;
			vertical-align: baseline;
			font-size: $font_size_6;
			color: $color_1;
		}
	}
}

/*================================================
07 - MODAL
==================================================*/
.modal-content{
    .modal-header{
        h4{
            font-size: $font_size_3;
        }
    }
    .modal-body{
        p{
            font-size: $font_size_6;
        }
        h3{
            font-size: $font_size_7;
            font-weight: $font-w600;
        }
    }
    .close {
        background: $color_1;
        border: none;
        font-size: 30px;
        color: $color_2;
    }
    table {
        border-collapse: collapse;
    }
    tr{
        border-bottom: 1px solid $color_2;
    }
    td{
       border-right: 1px solid $color_2;
       padding: 5px;
    }
}

/*================================================
09 - MEDIA QUERY
==================================================*/

$break-ipad-pro: 1200px;
$break-min-ipad: 768px;
$break-max-ipad: 991px;
$break-min-tab: 577px;
$break-max-tab: 767px;
$break-mob: 576px;

@media (min-width: 1200px){
	.container {
		max-width: 1030px !important;
	}
	
}

@media (min-width:$break-max-ipad)  and (max-width: $break-ipad-pro) {
	
}
    
@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {
	footer{
		position:unset !important;
	}
}

@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {

}

@media screen and (max-width: $break-min-ipad) {
	.content_part{
	width: 90% !important;
		.whitebox {
			padding: 15px;
		}
	}
	.partnersec{
		.age_button_inner {
			.age_button_inner_bg {
				h2{
					font-size: 18px;
				}
				a {
					padding: 4px 10px;
				}
			}
		}
	}
}

@media screen and (max-width: $break-mob) {

	header{
		display: none;
	}
	.content_part{
		border-radius: 0px !important;
		width: 100% !important;
		height: 485px !important;
		.whitebox {
			border-radius: 0px;
			bottom: 10px;
			left: 10px;
			right: 10px;
			h1{
				font-size: $font_size_2 !important;
    			text-align: center;
			}
			.contentbtn{
				font-size: 20px !important;
			}
		}
	}
	
	.reasontext h3, h4, h5{
		font-size: $font_size_5 !important;
	}

	.age_button_inner_bg{
		a{
			width: 32% !important;
		}
	}
	footer img {
		width: auto !important;
		margin: 0 2px;
	}
}