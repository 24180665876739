/*
*******************************************
Template Name:tax allowance awareness
Author: Abhilash GP
Date:01-11-2021
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
/*================================================
01 - Sass Variables
==================================================*/
.followupPage {
  $background_color_1: #ffffff;
  $background_color_2: #2f3996;
  $background_color_3: #3f4792;
  $background_color_4: #359f14;
  $background_color_5: #1e7b01;
  $background_color_6: rgba(21, 54, 111, 0.45);
  $color_1: #ffffff;
  $color_2: #000000;
  $color_3: #2f3996;
  $color_4: #881f1f;
  $font-normal: normal;
  // ======border===========
  $border_0: 0px;
  $border-rd4: 4px;
  $border-style1: solid 1px rgba(255, 255, 255, 0.30);
  $border-style2: 4px solid #23df6a;
  $border-style3: 2px solid #d5d5d5;
  $border-style4: 1px solid #b4b4b4;
  $border-style5: 1px solid #2f3996;
  /*================================================
	02 - Sass Mixins
	==================================================*/
  @mixin shadow-1 {
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
  }
  @mixin shadow-2 {
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
    -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
  }
  @mixin shadow-3 {
    -webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
  }
  @mixin shadow-4 {
    -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
    box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
  }
  @mixin rounded {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }

  @mixin radius_5 {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  /*================================================
	03 - BODY STYLES
	==================================================*/
  .address_section {
    width: 100%;
    border-top: 1px solid #494646;
    margin-top: 100px;
  }
  .address_section p {
    padding-bottom: 30px;
    margin-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-bottom: 0px;
    border-bottom: 1px solid #494646;
  }
  html {
    scroll-behavior: smooth;

  }
  a {
    cursor: pointer;
  }
  // ======BODY STYLE========
  body {
    padding: 0px;
    margin: 0px;
    &::before {
      z-index: -1;
      opacity: 0.3;
      content: "";
      position: fixed;
      height: 100%;
      width: 100%;
      background: #ffffff !important;
      background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
      background: -webkit-linear-gradient(top, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
      background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
      background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
      background: linear-gradient(to bottom, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0);
    }
  }
  .container {
    max-width: 600px !important;
  }
  // ======CUSTOM ELEMENTS========
  .nopad {
    padding: 0px !important;
  }
  .pl-0 {
    padding-left: 0px !important;
  }
  .pr-0 {
    padding-right: 0px !important;
  }
  .space-sec {
    margin-top: 9px;
    margin-left: 2px;
  }
  .pb-0 {
    padding-bottom: 0px !important;
  }
  .p-15 {
    padding: 0 15px !important;

    .spcl {
      max-width: 16%;
      margin: -6px 5px;
    }
  }
  .blue-bg {
    background: $background_color_2;
    @include radius_5;

    p {
      font-size: 15px;
      text-align: center;
      padding: 7px 10PX;
      color: $color_1;
    }
  }
  .bglogo {
    &::after {
      z-index: -1;
      content: "";
      position: fixed;
      height: 183px;
      width: 100%;
      top: 5%;
      background-image: url(/assets/Followup/img/logo_bg.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      opacity: 0.28;
    }
  }
  .arrow-btn-wrap {
    position: absolute;
    padding: 0px !important;
    bottom: 40px;
    left: 44%;

    .arrowMove {
      animation: arrowmove 0.9s infinite alternate;
      position: relative;
      width: 150px;
      z-index: 1;
    }
  }
  .btn-submit {
    background: $background_color_4 !important;
    color: $color_1 !important;
    padding: 12px 10px !important;
    border: 0px;
    border-bottom: solid 4px $background_color_5 !important;
    @include radius_5;
    width: 100% !important;
    margin-top: 15px;
    text-transform: uppercase;
    bottom: 0;
    position: fixed;
    left: 0;
  }
  h3 {
    font-size: 20px;
    text-align: center;
    padding: 10PX;
    color: $color_3;
  }
  h4 {
    font-size: 19px !important;
    text-align: center;
    padding: 10PX;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    color: #000;

    strong {
      font-weight: 700;
      color: #000;
    }
  }
  .form-control {
    background-color: $background_color_1;
    border: 2px solid #d5d5d5;
    font-size: 16px;
    font-weight: normal;
    height: 50px !important;
    outline: none !important;
    padding: 0px 10px;
  }
  .inp-wid {
    width: 92% !important;
  }
  .secured {
    position: relative;
    margin-top: 0px;
  }
  p.sec_t {
    color: #757575;
    font-size: 14px;
    margin-top: 10px;
    line-height: 22px;
  }
  // ======YES OR NO========
  .space {
    margin: 45% 0 !important;
  }
  .space2 {
    margin: 25% 0 !important;
  }
  legend {
    font-size: 16px !important;
    width: auto !important;
    padding: 0 10px !important;
    font-weight: normal !important;
  }
  fieldset {
    border: 1px solid #2f3996 !important;
    padding: 10px 10px 0 !important;
    border-radius: 4px !important;
    margin-bottom: 15px !important;
  }
  .radio-box {
    margin: 15px 0;
    display: inline-block;
    width: 100%;
    h5 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .styl_i {
      font-size: 14px;
      margin-bottom: 27px;
      line-height: 1.2em;
      color: #45474a;
      font-style: italic;
    }
    .styl2_i {
      font-size: 14px;
      margin-top: 27px;
      line-height: 1.2em;
      color: #45474a;
      font-style: italic;
    }
    .mar-5 {
      margin-bottom: 5px !important;
    }
    label.radioa {
      border: 2px solid $color_3;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      height: 48px;
      position: relative;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 0px 0px 0px 50px;
      line-height: 44px;
      margin-bottom: 10px;
      width: 100%;
      float: left;
      text-align: left;
      clear: none;
      color: $color_3;
      font-weight: normal;
      display: block;
      font-size: 14px;
      .st1 {
        background-color: #ff0505 !important;
        font-size: 22px;
      }
      span {
        position: absolute;
        left: 2px;
        color: $color_1;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        padding: 0px 14px;
        margin: 2px 0px;
        background-color: $background_color_2;
        border-radius: 5px;
      }
    }
    label.radio {
      background: rgba(255, 255, 255, 0.26);
      border: 2px solid $color_3;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      height: 48px;
      position: relative;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 0px 0px 0px 50px;
      line-height: 44px;
      margin-bottom: 10px;
      width: 100%;
      float: left;
      text-align: left;
      clear: none;
      color: $color_3;
      font-weight: normal;
      display: block;
      font-size: 17px;
    }
    label.l_g {
      background-color: rgba(0, 156, 127, 0.18) !important;
    }
    label.l_r {
      background-color: rgba(167, 34, 4, 0.18) !important;
      color: #ff0505;
      border: solid 2px #ff0505;
    }
    .bgno {
      background: rgba(255, 255, 255, 0.26) !important;
      color: $color_2;
    }
    .style3a {
      text-align: center;
      background-color: $background_color_5 !important;
      padding: 0px !important;
      border: solid 1px $color_4 !important;
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }
  // ======STATUS BAR========
  // ======Button ========
  .btn-next-pop {
    background: $background_color_4 !important;
    color: $color_1 !important;
    padding: 12px 10px !important;
    border: 0px;
    border-bottom: solid 4px $background_color_5 !important;
    @include radius_5;
    width: 100% !important;
    margin-top: 15px;
    text-transform: uppercase;
    bottom: 0;
    position: fixed;
    left: 0;
    z-index: 9999;
  }
  .share-btn {
    padding: 5px 7px;
    color: $color_1;
    font-size: 14px;
    font-weight: normal;
    border: 0px;
    border-radius: 5px;
    font-style: italic;
    margin-left: 7px;
  }
  .add-btn {
    background: none;
    padding: 5px 10px;
    color: red;
    font-size: 14px;
    font-weight: normal;
    border: 2px solid red;
    border-radius: 5px;
    font-style: italic;
    min-width: 90px;
  }
  .error {
    display: none;
    color: #fff;
    background: #f00;
    width: 100%;
    padding: 2px 5px;
    float: left;
    text-align: center;
    border-radius: 4px;
    font-size: 13px;
  }
  .btn-clear {
    background-color: red;
    color: $color_1 !important;
    font-size: 15px !important;
    border-radius: $border-rd4;
    padding: 9px 15px;
    border: $border_0;
    width: auto;
    cursor: pointer;
    margin-bottom: 10px;
    text-transform: uppercase;
    &:hover {
      background-color: $background_color_2;
    }
  }
  .slide2 {
    margin: 20px 0 0;
  }
  .flp-loader {
    font-size: 20px !important;
    text-align: center !important;
    padding: 10px !important;
    color: #2f3996 !important;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
  }
  .submitpop {
    background-color: $background_color_4;
    color: $color_1 !important;
    font-size: 15px !important;
    border-radius: $border-rd4;
    padding: 9px 20px;
    border: $border_0;
    width: 220px;
    cursor: pointer;
    margin-bottom: 10px;
    text-transform: uppercase;
    float: right;
    text-align: center;
    &:hover {
      background-color: $background_color_2;
    }
  }
  .data-div {
    padding: 0px 0 !important;
    background: $background_color_1;
    margin: 70px 0 40px !important;
    @include radius_5;
  }
  .blink_me {
    animation: blinker 1s linear infinite;
    color: red;
  }
  .custom-checkbox {
    display: none;

  }
  .slctbdr {
    border: 2px solid #540463 !important;
  }
  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }
  .mb20 {
    margin-bottom: 0px !important;
  }
  .mt20 {
    margin-top: 20px !important;
  }
  /*================================================
	09 - MODAL 
	==================================================*/
  .w25px {
    width: 25px;
  }
  .inp-cust {
    width: 24px;
    height: 34px;
    border: 0px;
    outline: none;
    box-shadow: none;
    text-align: center;
    border: inset 1px#f9f9f9;
    margin: 0 auto 0 3px;
  }
  .modal-backdrop {
    z-index: 1 !important;
  }
  .modal {
    padding-right: 0px !important;
  }
  .modal-content-q {
    top: 50px;
    position: relative;
  }
  .modal-content {
    border: 0px !important;
    .modal-header {
      background: $background_color_4;
      border-radius: $border_0 !important;
      text-align: center;
      .close {
        color: $color_1 !important;
      }
      .modal-title {
        color: $color_1;
        font-size: 18px;
        font-weight: 500px !important;
      }
      .modal-body {
        h2 {
          color: #2f3996 !important;
          font-size: 18px !important;
          font-weight: 600;
          margin-bottom: 20px;
        }
        ol {
          li {

            padding: 5px 0;
            color: $color_2;

            a {
              color: $color_2 !important;
            }
          }
        }
      }
    }
    .modal-footer {
      background: $background_color_1;
      border: $border_0;
      padding: 10px 20px !important;
    }
    h3 {
      font-size: 18px;
      color: $color_2;
    }
    p {
      font-size: 14px;
      margin-bottom: 7px;
    }
  }
  .modal-open {
    .modal {
      background: rgba(0, 0, 0, 0.85) !important;
    }
  }
  .sign-sec {
    margin-bottom: 20px;
    ul {
      margin: 0px;
      padding: 0 0px;
      li {
        background: url(/assets/Followup/img/arrow-li.png) no-repeat left center;
        list-style: none;
        padding: 5px 0 5px 35px;
        margin-bottom: 0px;
        font-size: 15px;
      }
    }
    .t-area {
      min-height: 200px;
    }
    .sign-div {
      padding: 20px 0 0;

      .sign-text {
        position: absolute;
        z-index: 999;
        font-size: 17px;
        color: #5f5f5f;
        width: 94.5%;

        img {
          margin-top: 100px;
        }
      }
    }
    .signature_canvaseWrap {
      .signature-pad {
        border: 1px dotted #000 !important;
        background: url(/assets/Followup/img/sign-bg.png) no-repeat #fff 49% 67px !important;
        border-radius: 5px;
        display: block;
        margin: 20px auto;
        position: relative;
        width: 90%;
        height: 250px;
      }
    }
  }
  .sign_msg {
    color: #fff;
    width: 95%;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 0;
    text-align: center;
    margin: 0px auto;
  }
  .pdf-section {
    background-color: $background_color_1;
    min-height: 500px;
    padding: 10px !important;
    margin-bottom: 15px;
    border-bottom: 10px solid #495057;
    &:last-child {
      border-bottom: 2px solid #495057;
    }
    .logo {
      width: 185px;
    }
    .headtxt {
      p {
        font-size: 13px;
        color: $color_2;

      }
    }
  }
  .content_txt {
    h2 {
      font-size: 26px;
      color: $color_2;
      margin-bottom: 15px;
      font-weight: bold;
      margin-top: 20px;
    }
    p {
      font-size: 12px;
      color: $color_2;
      font-weight: normal;
      margin: 0px;
    }
    h3 {
      font-size: 15px;
      color: $color_2;
      font-weight: normal;
      margin: 5px 0 !important;
      text-align: left;
    }
    h4 {
      font-size: 15px !important;
      font-weight: 600;
      margin: 5px 0;
      text-align: center;
    }
    .text_small {

      p {
        font-size: 10px !important;
        color: $color_2;
        font-weight: normal;
        margin: 0px;
        line-height: 11px;

      }
    }
  }
  .text-left {
    text-align: left !important;
  }
  .content_txt_terms {
    h2 {
      font-size: 20px;
      color: $color_2;
      margin-bottom: 15px;
      font-weight: bold;
      margin-top: 20px;
    }
    p {
      font-size: 14px;
      color: $color_2;
      font-weight: 600;
      margin: 0px 0 15px;
    }
    h3 {
      font-size: 17px;
      color: $color_2;
      font-weight: 800;
      margin: 5px 0 15px;
      text-align: left !important;
    }
    h4 {
      font-size: 14px !important;
      color: #000 !important;
      font-weight: 600;
      margin: 10px 0 !important;
      text-align: left !important;
    }
    ul {
      li {
        font-size: 14px;
        color: $color_2;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
  .w90 {
    width: 90px;
  }
  .w35 {
    width: 35px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
  .w100 {
    width: 115px;
    height: 45px;
  }
  .h100 {
    height: 111px;
  }
  .h75 {
    height: 75px;
  }
  .pad5 {
    padding: 5px;
  }
  .pad7 {
    padding: 7px 5px;
  }
  .padb5 {
    padding-bottom: 5px !important;
  }
  .padb10 {
    padding-bottom: 10px;
  }
  .mtop25 {
    margin-top: 25px;
  }
  .mtb25 {
    margin: 20px 0;
  }
  .mtb10 {
    margin: 10px 0;
    td {
      border: 1px solid #777777;
    }
    .bnone {
      border: none;
    }
    .bt-0 {
      border-top: 0px;
    }
    .bb-0 {
      border-bottom: 0px;
    }
  }
  .brb_1 {
    border-bottom: 1px solid #000;
  }

  .mb0 {
    margin-bottom: 0px !important;
  }
  .bor-r {
    border-right: 1px solid #808080 !important;
  }
  .name-div {
    width: 100%;
    border-bottom: solid 1px $color_2;
    padding: 25px 0 10px !important;
    font-size: 13x !important;
    color: $color_2;
    font-weight: normal;
    margin: 0px;
  }
  .sign_div {
    width: 100%;
    border: solid 1px #000;
    padding: 30px 15px !important;
    font-size: 13x !important;
    color: #000;
    font-weight: normal;
    margin: 0px;
    min-height: 170px;
  }
  .client_div {
    width: 100%;
    border: solid 1px #376289;
    padding: 15px !important;
    margin: 0px;
    border-radius: 45px;
    margin-bottom: 15px;
  }
  .redtext {
    color: red !important;
  }
  .bor-box {
    border: solid 1px $color_2;
  }
  .pl75 {
    padding-left: 15px !important;
  }
  .pt15 {
    padding-top: 15px;
  }
  .grey_bg {
    background-color: #e6e7e9;
  }
  .q-col {
    padding: 7px 0;
    p {
      font-size: 14px;
      color: $color_2;
      font-weight: normal;
      margin: 0px;
      padding-bottom: 7px;
    }
    .q-num {
      width: 35px;
      padding-bottom: 7px;
    }
    .an-box {
      font-weight: bold;
      width: 85px;
      color: #000;
    }
    .an-box2 {
      font-weight: bold;
    }
    .y-box {
      width: 85px;
    }
    .n-box {
      width: 85px;
    }

    .green-tick {

      height: 23px;
      position: relative;
      background-repeat: no-repeat;
      display: inline-block;
    }
    .red-tick {
      height: 23px;
      position: relative;
      background-repeat: no-repeat;
      display: inline-block;
    }
    .tick-col {
      margin: 0 3px;
      width: 24px;
    }
  }
  .button {
    background: #00b63d;
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 200px;
    display: inline-block;
    text-align: center;
    font-size: 17px;
    -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
    font-weight: 800;
    cursor: pointer;
    color: #FFFFFF !important;
    border: solid 1px #16ef5f;
    text-decoration: none !important;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .table {
    th {
      padding: 10px !important;
      vertical-align: middle !important;
    }
    td {
      padding: 10px !important;
      vertical-align: middle !important;
    }
  }
  .f-normal {
    font-weight: 400 !important;
  }
  // ======ANIMATION ========
  .animated-effect {
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 #359f14;
  }
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    font-size: 17px;
  }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  .table th {
    padding: 10px !important;
    vertical-align: middle !important;
  }
  .table td {
    padding: 10px !important;
    vertical-align: middle !important;
    font-size: 17px;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .flp-qtext {
    color: #444 !important;
    text-align: left;
    font-weight: 510 !important;
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .btn-primary {
    background: #0070ba !important;
    border-color: rgba(9, 62, 177, 0.68) !important;
  }
  .error_msg {
    color: #fff;
    width: 100%;
    float: left;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
  }
  @-webkit-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }
  @-moz-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }
  @-ms-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }
  @keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);

    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  .animated {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
      -webkit-transition: -webkit-transform 2.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0)
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1
    }
  }
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
  // ====== MEDIA QUERY ========
  @media screen and (max-width:1700px) and (min-width:992px) {
    /*
	*******************************************
	Template Name:tax allowance awareness
	Author: Abhilash GP
	Date:01-11-2021
	Template By: Vandalay Designs
	Copyright 2021-2022 Vandalay Business Solution
	
	* This file contains the styling for the TMS Legal, this
	is the file you need to edit to change the look of the
	site.
	
	This files contents are outlined below >>>>
	
	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************

	============================================
	==== T A B L E   O F   C O N T E N T S =====
	============================================
	01 - Sass Variables
	02 - Sass Mixins
	03 - BODY 
	04 - HEADER 
	05 - Questionnaie 
	
	08 - FOOTER 
	09 - MODAL 
	10 - MEDIA QUERY
	11 - ANIMATION
  /*================================================
	    01 - Sass Variables
  ==================================================*/
    .followupPage {
      $background_color_1: #ffffff;
      $background_color_2: #2f3996;
      $background_color_3: #3f4792;
      $background_color_4: #359f14;
      $background_color_5: #1e7b01;
      $background_color_6: rgba(21, 54, 111, 0.45);
      $color_1: #ffffff;
      $color_2: #000000;
      $color_3: #2f3996;
      $color_4: #881f1f;
      $font-normal: normal;
      // ======border===========
      $border_0: 0px;
      $border-rd4: 4px;
      $border-style1: solid 1px rgba(255, 255, 255, 0.30);
      $border-style2: 4px solid #23df6a;
      $border-style3: 2px solid #d5d5d5;
      $border-style4: 1px solid #b4b4b4;
      $border-style5: 1px solid #2f3996;
      /*================================================
	        02 - Sass Mixins
	    ==================================================*/

      @mixin shadow-1 {
        -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
      }

      @mixin shadow-2 {
        -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
        -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.90);
      }

      @mixin shadow-3 {
        -webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
        -moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
        box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
      }

      @mixin shadow-4 {
        -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
        -moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
        box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
      }

      @mixin rounded {
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        border-radius: 100%;
      }

      @mixin radius_5 {
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
      /*================================================
	        03 - BODY STYLES
	    ==================================================*/
      html {
        scroll-behavior: smooth;

      }
      a {
        cursor: pointer;
      }
      // ======BODY STYLE========
      body {
        padding: 0px;
        margin: 0px;
        &::before {
          z-index: -1;
          opacity: 0.3;
          content: "";
          position: fixed;
          height: 100%;
          width: 100%;
          background: #ffffff !important;
          background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
          background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
          background: -webkit-linear-gradient(top, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
          background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
          background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
          background: linear-gradient(to bottom, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0);
        }
      }
      .container {
        max-width: 600px !important;
      }
      // ======CUSTOM ELEMENTS========
      .nopad {
        padding: 0px !important;
      }
      .pl-0 {
        padding-left: 0px !important;
      }
      .pr-0 {
        padding-right: 0px !important;
      }
      .space-sec {
        margin-top: 9px;
        margin-left: 2px;
      }
      .pb-0 {
        padding-bottom: 0px !important;
      }
      .p-15 {
        padding: 0 15px !important;
        .spcl {
          max-width: 16%;
          margin: -6px 5px;
        }
      }
      .blue-bg {
        background: $background_color_2;
        @include radius_5;

        p {
          font-size: 15px;
          text-align: center;
          padding: 7px 10PX;
          color: $color_1;
        }
      }
      &::after {
        z-index: -1;
        content: "";
        position: fixed;
        height: 183px;
        width: 100%;
        top: 5%;
        background-image: url(/assets/Followup/img/logo_bg.png) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        opacity: 0.28;
      }
      .bglogo {
        z-index: -1;
        content: "";
        position: fixed;
        height: 183px;
        width: 100%;
        top: 5%;
        background-image: url(/assets/Followup/img/logo_bg.png) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        opacity: 0.28;
      }
      .btn-submit {
        background: $background_color_4 !important;
        color: $color_1 !important;
        padding: 12px 10px !important;
        border: 0px;
        border-bottom: solid 4px $background_color_5 !important;
        @include radius_5;
        width: 100% !important;
        margin-top: 15px;
        text-transform: uppercase;
        bottom: 0;
        position: fixed;
        left: 0;
      }
      h3 {
        font-size: 20px;
        text-align: center;
        padding: 10PX;
        color: $color_3;
      }
      h4 {
        font-size: 19px !important;
        text-align: center;
        padding: 10PX;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        color: #000;

        strong {
          font-weight: 700;
          color: #000;
        }
      }
      .form-control {
        background-color: $background_color_1;
        border: 2px solid #d5d5d5;
        font-size: 16px;
        font-weight: normal;
        height: 50px !important;
        outline: none !important;
        padding: 0px 10px;

      }
      .inp-wid {
        width: 92% !important;
      }
      .secured {
        position: relative;
        margin-top: 0px;
      }
      p.sec_t {
        color: #757575;
        font-size: 14px;
        margin-top: 10px;
        line-height: 22px;
      }
      // ======YES OR NO========
      .space {
        margin: 45% 0 !important;
      }
      .space2 {
        margin: 25% 0 !important;
      }
      legend {
        font-size: 16px !important;
        width: auto !important;
        padding: 0 10px !important;
        font-weight: normal !important;
      }
      fieldset {
        border: 1px solid #2f3996 !important;
        padding: 10px 10px 0 !important;
        border-radius: 4px !important;
        margin-bottom: 15px !important;
      }
      .radio-box {
        margin: 15px 0;
        display: inline-block;
        width: 100%;
        h5 {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 20px;
        }
        .styl_i {
          font-size: 14px;
          margin-bottom: 27px;
          line-height: 1.2em;
          color: #45474a;
          font-style: italic;
        }
        .styl2_i {
          font-size: 14px;
          margin-top: 27px;
          line-height: 1.2em;
          color: #45474a;
          font-style: italic;
        }
        .mar-5 {
          margin-bottom: 5px !important;
        }
        label.radioa {
          border: 2px solid $color_3;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          height: 48px;
          position: relative;
          cursor: pointer;
          outline: none;
          margin: 0;
          padding: 0px 0px 0px 50px;
          line-height: 44px;
          margin-bottom: 10px;
          width: 100%;
          float: left;
          text-align: left;
          clear: none;
          color: $color_3;
          font-weight: normal;
          display: block;
          font-size: 14px;
          .st1 {
            background-color: #ff0505 !important;
            font-size: 22px;
          }
          span {
            position: absolute;
            left: 2px;
            color: $color_1;
            font-weight: 400;
            height: 40px;
            line-height: 40px;
            padding: 0px 14px;
            margin: 2px 0px;
            background-color: $background_color_2;
            border-radius: 5px;
          }
        }
        label.radio {
          background: rgba(255, 255, 255, 0.26);
          border: 2px solid $color_3;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          height: 48px;
          position: relative;
          cursor: pointer;
          outline: none;
          margin: 0;
          padding: 0px 0px 0px 50px;
          line-height: 44px;
          margin-bottom: 10px;
          width: 100%;
          float: left;
          text-align: left;
          clear: none;
          color: $color_3;
          font-weight: normal;
          display: block;
          font-size: 17px;
        }
        label.l_g {
          background-color: rgba(0, 156, 127, 0.18) !important;
        }
        label.l_r {
          background-color: rgba(167, 34, 4, 0.18) !important;
          color: #ff0505;
          border: solid 2px #ff0505;
        }
        .bgno {
          background: rgba(255, 255, 255, 0.26) !important;
          color: $color_2;
        }
        .style3a {
          text-align: center;
          background-color: $background_color_5 !important;
          padding: 0px !important;
          border: solid 1px $color_4 !important;
          margin-top: 10px;
        }
      }
      @media screen and (min-width: 1px) {
        input.radio {
          position: absolute;
          left: -9999px;
        }
      }
      // ======STATUS BAR========
      // ======Button ========
      .btn-next-pop {
        background: $background_color_4 !important;
        color: $color_1 !important;
        padding: 12px 10px !important;
        border: 0px;
        border-bottom: solid 4px $background_color_5 !important;
        @include radius_5;
        width: 100% !important;
        margin-top: 15px;
        text-transform: uppercase;
        bottom: 0;
        position: fixed;
        left: 0;
        z-index: 9999;
      }
      .share-btn {
        padding: 5px 7px;
        color: $color_1;
        font-size: 14px;
        font-weight: normal;
        border: 0px;
        border-radius: 5px;
        font-style: italic;
        margin-left: 7px;
      }
      .add-btn {
        background: none;
        padding: 5px 10px;
        color: red;
        font-size: 14px;
        font-weight: normal;
        border: 2px solid red;
        border-radius: 5px;
        font-style: italic;
        min-width: 90px;
      }
      .error {
        display: none;
        color: #fff;
        background: #f00;
        width: 100%;
        padding: 2px 5px;
        float: left;
        text-align: center;
        border-radius: 4px;
        font-size: 13px;
      }
      .btn-clear {
        background-color: red;
        color: $color_1 !important;
        font-size: 15px !important;
        border-radius: $border-rd4;
        padding: 9px 15px;
        border: $border_0;
        width: auto;
        cursor: pointer;
        margin-bottom: 10px;
        text-transform: uppercase;

        &:hover {
          background-color: $background_color_2;
        }
      }
      .slide2 {
        margin: 20px 0 0;
      }
      .submitpop {
        background-color: $background_color_4;
        color: $color_1 !important;
        font-size: 15px !important;
        border-radius: $border-rd4;
        padding: 9px 20px;
        border: $border_0;
        width: 220px;
        cursor: pointer;
        margin-bottom: 10px;
        text-transform: uppercase;
        float: right;
        text-align: center;
        &:hover {
          background-color: $background_color_2;
        }
      }
      .data-div {
        padding: 0px 0 !important;
        background: $background_color_1;
        margin: 70px 0 40px !important;
        @include radius_5;
      }
      .blink_me {
        animation: blinker 1s linear infinite;
        color: red;
      }
      .custom-checkbox {
        display: none;
      }
      .slctbdr {
        border: 2px solid #540463 !important;
      }
      @media screen and (min-width: 1px) {
        input.radio {
          position: absolute;
          left: -9999px;
        }
      }
      .mb20 {
        margin-bottom: 0px !important;
      }
      .mt20 {
        margin-top: 20px !important;
      }
      /*================================================
	      09 - MODAL 
	    ==================================================*/
      .modal-backdrop {
        z-index: 1 !important;

        .first-modal {}
      }

      .modal {
        padding-right: 0px !important;
      }

      .modal-content {
        border: 0px !important;
        margin-top: 40%;

        .modal-header {
          background: $background_color_4;
          border-radius: $border_0 !important;
          text-align: center;

          .close {
            color: $color_1 !important;
          }

          .modal-title {
            color: $color_1;
            font-size: 18px;
            font-weight: 500px !important;
          }

          .modal-body {
            h2 {
              color: #2f3996 !important;
              font-size: 18px !important;
              font-weight: 600;
              margin-bottom: 20px;
            }

            ol {
              li {

                padding: 5px 0;
                color: $color_2;

                a {
                  color: $color_2 !important;
                }
              }
            }
          }

        }

        .modal-footer {
          background: $background_color_1;
          border: $border_0;
          padding: 10px 20px !important;
        }

        h3 {
          font-size: 18px;
          color: $color_2;
        }

        p {
          font-size: 14px;
          margin-bottom: 7px;
        }
      }

      .modal-open {
        .modal {
          background: rgba(0, 0, 0, 0.85) !important;
        }
      }

      .sign-sec {

        margin-bottom: 20px;

        ul {
          margin: 0px;
          padding: 0 0px;

          li {
            background: url(/assets/Followup/img/arrow-li.png) no-repeat left center;
            list-style: none;
            padding: 5px 0 5px 35px;
            margin-bottom: 0px;
            font-size: 15px;
          }
        }
        .t-area {
          min-height: 200px;
        }
        .sign-div {
          padding: 20px 0 0;

          .sign-text {
            position: absolute;
            z-index: 999;
            font-size: 17px;
            color: #5f5f5f;
            width: 94.5%;

            img {
              margin-top: 100px;
            }
          }
        }
        .signature_canvaseWrap {
          .signature-pad {
            border: 1px dotted #000 !important;
            background: url(/assets/Followup/img/sign-bg.png) no-repeat #fff 49% 67px !important;
            border-radius: 5px;
            display: block;
            margin: 20px auto;
            position: relative;
            width: 90%;
            height: 250px;
          }
        }
      }
      .sign_msg {
        color: #fff;
        width: 95%;
        background: red;
        font-size: 13px;
        padding: 0 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        margin-bottom: 0;
        text-align: center;
        margin: 0px auto;
      }
      .pdf-section {

        background-color: $background_color_1;
        min-height: 500px;
        padding: 10px !important;
        margin-bottom: 15px;
        border-bottom: 10px solid #495057;

        &:last-child {
          border-bottom: 2px solid #495057;
        }

        .logo {
          width: 185px;
        }

        .headtxt {
          p {
            font-size: 13px;
            color: $color_2;

          }
        }
      }
      .content_txt {
        h2 {
          font-size: 26px;
          color: $color_2;
          margin-bottom: 15px;
          font-weight: bold;
          margin-top: 20px;
        }

        p {
          font-size: 12px;
          color: $color_2;
          font-weight: normal;
          margin: 0px;
        }

        h3 {
          font-size: 15px;
          color: $color_2;
          font-weight: normal;
          margin: 5px 0 !important;
          text-align: left;
        }

        h4 {
          font-size: 15px !important;
          font-weight: 600;
          margin: 5px 0;
          text-align: center;
        }

        .text_small {

          p {
            font-size: 10px !important;
            color: $color_2;
            font-weight: normal;
            margin: 0px;
            line-height: 11px;

          }
        }
      }

      .text-left {
        text-align: left !important;
      }

      .content_txt_terms {
        h2 {
          font-size: 20px;
          color: $color_2;
          margin-bottom: 15px;
          font-weight: bold;
          margin-top: 20px;
        }

        p {
          font-size: 14px;
          color: $color_2;
          font-weight: 600;
          margin: 0px 0 15px;
        }

        h3 {
          font-size: 17px;
          color: $color_2;
          font-weight: 800;
          margin: 5px 0 15px;
          text-align: left !important;
        }

        h4 {
          font-size: 14px !important;
          color: #000 !important;
          font-weight: 600;
          margin: 10px 0 !important;
          text-align: left !important;
        }
        ul {
          li {
            font-size: 14px;
            color: $color_2;
            font-weight: 600;
            margin-bottom: 15px;
          }
        }

      }
      .w90 {
        width: 90px;
      }
      .w35 {
        width: 35px;
        font-weight: bold;
        font-size: 16px;
        color: black;
      }
      .w100 {
        width: 100px;
      }
      .h100 {
        height: 100px;
      }
      .h75 {
        height: 75px;
      }

      .pad5 {
        padding: 5px;
      }

      .pad7 {
        padding: 7px 5px;
      }

      .padb5 {
        padding-bottom: 5px !important;
      }

      .padb10 {
        padding-bottom: 10px;
      }

      .mtop25 {
        margin-top: 25px;
      }

      .mtb25 {
        margin: 20px 0;
      }
      .mtb10 {
        margin: 10px 0;

        td {
          border: 1px solid #777777;
        }

        .bnone {
          border: none;
        }
        .bt-0 {
          border-top: 0px;
        }
        .bb-0 {
          border-bottom: 0px;
        }
      }
      .mb0 {
        margin-bottom: 0px !important;
      }
      .bor-r {
        border-right: 1px solid #808080 !important;
      }
      .name-div {
        width: 100%;
        border-bottom: solid 1px $color_2;
        padding: 25px 0 10px !important;
        font-size: 13x !important;
        color: $color_2;
        font-weight: normal;
        margin: 0px;

      }
      .sign_div {
        width: 100%;
        border: solid 1px #000;
        padding: 30px 15px !important;
        font-size: 13x !important;
        color: #000;
        font-weight: normal;
        margin: 0px;
        min-height: 170px;
      }
      .client_div {
        width: 100%;
        border: solid 1px #376289;
        padding: 15px !important;
        margin: 0px;
        border-radius: 45px;
        margin-bottom: 15px;
      }
      .redtext {
        color: red !important;
      }
      .bor-box {
        border: solid 1px $color_2;
      }
      .pl75 {
        padding-left: 15px !important;
      }
      .pt15 {
        padding-top: 15px;
      }
      .grey_bg {
        background-color: #e6e7e9;
      }
      .q-col {
        padding: 7px 0;
        p {
          font-size: 14px;
          color: $color_2;
          font-weight: normal;
          margin: 0px;
          padding-bottom: 7px;
        }
        .q-num {
          width: 35px;
          padding-bottom: 7px;
        }
        .an-box {
          font-weight: bold;
          width: 85px;
          color: #000;
        }
        .an-box2 {
          font-weight: bold;

        }
        .y-box {
          width: 85px;
        }
        .n-box {
          width: 85px;
        }
        .red-tick {
          height: 23px;
          position: relative;
          background-repeat: no-repeat;
          display: inline-block;
        }
        .tick-col {
          margin: 0 3px;
          width: 24px;
        }
      }
      .button {
        background: #00b63d;
        padding: 10px 20px;
        border-radius: 5px;
        min-width: 200px;
        display: inline-block;
        text-align: center;
        font-size: 17px;
        -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.51);
        font-weight: 800;
        cursor: pointer;
        color: #FFFFFF !important;
        border: solid 1px #16ef5f;
        text-decoration: none !important;
        left: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
      }
      .table {
        th {
          padding: 10px !important;
          vertical-align: middle !important;
        }
        td {
          padding: 10px !important;
          vertical-align: middle !important;
        }
      }
      .f-normal {
        font-weight: 400 !important;
      }
      // ======ANIMATION ========
      .animated-effect {
        -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        box-shadow: 0 0 0 0 #359f14;
      }
      .table .thead-light th {
        color: #495057;
        background-color: #e9ecef;
        border-color: #dee2e6;
        font-size: 17px;
      }
      .table-bordered thead th,
      .table-bordered thead td {
        border-bottom-width: 2px;
      }
      .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
      }
      .table-bordered th,
      .table-bordered td {
        border: 1px solid #dee2e6;
      }
      .table th {
        padding: 10px !important;
        vertical-align: middle !important;
      }
      .table td {
        padding: 10px !important;
        vertical-align: middle !important;
        font-size: 17px;
      }
      .hide {
        display: none !important;
      }
      .show {
        display: block !important;
      }
      .flp-qtext {
        color: #444 !important;
        text-align: left;
        font-weight: 510 !important;
        font-size: 20px !important;
        margin-bottom: 10px !important;
      }
      .btn-primary {
        background: #0070ba !important;
        border-color: rgba(9, 62, 177, 0.68) !important;
      }
      .error_msg {
        color: #fff;
        width: 100%;
        float: left;
        background: red;
        font-size: 13px;
        padding: 0 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        margin-bottom: 10px;
      }

      @-webkit-keyframes pulse {
        to {
          box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
      }
      @-moz-keyframes pulse {
        to {
          box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
      }

      @-ms-keyframes pulse {
        to {
          box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
      }

      @keyframes pulse {
        to {
          box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
      }

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
      @-webkit-keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
      .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
      }
      @-webkit-keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
        }
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
      @keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);

        }
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
      .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
      }

      .animated {
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      @keyframes fadeInUp {
        from {
          transform: translate3d(0, 40px, 0);
          -webkit-transition: -webkit-transform 2.6s ease-in-out;
          transition: transform 0.6s ease-in-out;
        }

        to {
          transform: translate3d(0, 0, 0);
          opacity: 1
        }
      }
      @-webkit-keyframes fadeInUp {
        from {
          transform: translate3d(0, 40px, 0)
        }

        to {
          transform: translate3d(0, 0, 0);
          opacity: 1
        }
      }
      .fadeInUp {
        opacity: 0;
        animation-name: fadeInUp;
        -webkit-animation-name: fadeInUp;
      }
      .u_sign {
        height: 110px;
      }
      .p_sign {
        height: 110px;
      }
      // ====== MEDIA QUERY ========
      @media screen and (max-width:1700px) and (min-width:992px) {

        &::after {
          background-size: 15%;
        }
      }
      @media screen and (max-width:992px) and (min-width: 768px) {
        &::after {
          background-size: 15%;
        }
        .container-fluid {
          width: 100% !important;
        }
        .modal-dialog {
          max-width: 100%;
        }
      }
      @media screen and (max-width: 767px) {
        .followupPage {
          .pl75 {
            padding: 15px !important;
          }
        }
        .container-fluid {
          width: 100% !important;
        }
        &::after {
          background-size: 40%;
        }
        .pdf-section {
          padding: 10px !important;
        }
        .data-div {
          padding: 0px 0 0px !important;
          margin: 50px 0 55px !important;
        }
        .table {
          th {
            padding: 10px !important;
            vertical-align: middle !important;
          }
          td {
            padding: 10px !important;
            vertical-align: middle !important;
          }
        }
        .mb20 {
          margin-bottom: 0px !important;
        }
        .mt20 {
          margin-top: 20px !important;
        }
      }
      @media (max-width: 576px) {
        &::after {
          background-size: 40%;
        }
        .container {
          max-width: 100%;
        }
        .mob-pad5 {
          padding: 5px !important;
        }
      }
    }
    .bglogo {
      &::after {
        background-size: 15%;
      }
    }
  }
  @media screen and (max-width:992px) and (min-width: 768px) {
    .bglogo {
      &::after {
        background-size: 15%;
      }
    }
    .container-fluid {
      width: 100% !important;
    }

    .modal-dialog {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .followupPage {
      .pl75 {
        padding: 15px !important;
      }
    }
    .container-fluid {
      width: 100% !important;
    }
    .bglogo {
      &::after {
        background-size: 40%;
      }
    }
    .pdf-section {
      padding: 10px !important;
    }
    .data-div {
      padding: 0px 0 0px !important;
      margin: 50px 0 55px !important;
    }
    .table {
      th {
        padding: 10px !important;
        vertical-align: middle !important;
      }
      td {
        padding: 10px !important;
        vertical-align: middle !important;
      }
    }
    .mb20 {
      margin-bottom: 0px !important;
    }
    .mt20 {
      margin-top: 40px !important;
    }
    .arrow-btn-wrap {
      left: 29% !important;

      .arrowMoveJoint {}
    }
  }
  @media (max-width: 576px) {
    .bglogo {

      &::after {
        background-size: 40%;
      }
    }
    .container {
      max-width: 100%;
    }
    .mob-pad5 {
      padding: 5px !important;
    }
  }
  .unsubscribe_btn {
    text-decoration: none;
  }
}
.modal-content-q {
  top: 50px;
}