/*
    *******************************************
    Template Name: Terms-mywillmyway
    Author: nikhil
    Date:31-05-2022
    Template By: Vandalay Designs
    Copyright 2021-2022 Vandalay Business Solution
    
    * This file contains the styling for the Terms-mywillmyway, this
    is the file you need to edit to change the look of the
    site.
    
    This files contents are outlined below >>>>
    
    *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
    *******************************************
    
    ============================================
    ==== T A B L E   O F   C O N T E N T S =====
    ============================================
    
    01 - Sass Variables
    02 - body
    03 - modal 
    04 - media query 
    
    ============================================
    ============================================
    
    */
    
    /*================================================
    01 - Sass Variables
    ==================================================*/
   
    
    html {
        scroll-behavior: smooth;
      }
    
    a{cursor: pointer;}
    
    /*================================================
    02 - Body
    ==================================================*/
    
    .content-section{
      font-family: var(--bs-body-font-family) !important;
    }
    
    /*================================================
    03 - Modal
    ==================================================*/
    

    /*================================================
    04 - Media Query
    ==================================================*/
    
    $max-break-tab:1199.98px;
    $min-break-tab:991px;
    $max-break-tablet:990px;
    $min-break-tablet:768px;
    $max-break-small: 767px;
    $min-break-small: 576px;
    $max-break-mob: 575px;
    
    @media (max-width:$max-break-small) {
       
    }
    
    @media (max-width:$min-break-small) {

    }
    
