 


 $break-mob: 576px;

 @media screen and (max-width: $break-mob) {

    .bannergroup {
        h1{
            padding: 30px 15px 8px;
        }
        &::before {
          height: 380px !important;
        }
    }

    .secondwillsecs{
        &::before {
            height: 380px !important;
        }
    }
 }